<script>
import Layout from "../../layouts/main.vue";

export default {
    components: {
        Layout,
    },
    mounted() {
        this.$store.dispatch(
            "apidata/fetchUserInfo",
            localStorage.cs_user_token
        );
    },
};
</script>

<template>
    <Layout>
        <div class="my-5 pt-5">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="text-center my-5">
                            <h1 class="font-weight-bold text-error">
                                Thank you
                            </h1>

                            <div class="mt-5 text-center">
                                <router-link
                                    to="/"
                                    class="btn btn-primary waves-effect waves-light"
                                >
                                    Back to dashboard
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
